#homeCarousel .carousel-item {
  width: 100%;
  height: 400px;
  border-radius: 55px 55px 55px 55px;
  overflow: hidden;
}

.carousel .carousel-item {
  width: 100%;
  height: 400px;
  /* border-radius: 55px 55px 55px 55px; */
  overflow: hidden;
}

.carousel-item img {
  object-position: center;
  object-fit: cover;
  height: 60vh;
}